// @flow
import React from 'react';
import { get } from 'lodash';
import Helmet from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import type { WebsiteMdl } from '../../../../cms/models/website';
import type { WebsiteMetaTagsMdl } from '../../../../cms/models/metaTags';
import { includePixel } from '../../../../utils/env';

export const getMetaTagsFromWebsite = (website: WebsiteMdl): WebsiteMetaTagsMdl | null => {
  return get(website, 'metaTags', null);
};

type Props = {
  metaTags: WebsiteMetaTagsMdl | null,
};

const MetaTagsWrapper = ({ metaTags }: Props) => {
  if (!metaTags) return null;
  const pageTitle = get(metaTags, 'pageTitle', '');
  const shareImage = get(metaTags, 'shareImage.file.url', '');
  const additionalTags = get(metaTags, 'additionalMetaTags.additionalMetaTags', '');
  return (
    <Helmet title={pageTitle}>
      {includePixel() && (
        <script id="cidsyncjs" src="https://pixel-static.spotify.com/sync.min.js" async />
      )}
      {includePixel() && (
        <noscript>
          {`<img
            id="cidmgmt"
            src="https://pixel.spotify.com/v1/sync?nojs=1"
            style={{ display: 'none' }}
            width="0"
            height="0"
          />`}
        </noscript>
      )}
      <meta property="og:image" content={shareImage} />
      <meta name="twitter:image" content={shareImage} />
      {ReactHtmlParser(additionalTags)}
    </Helmet>
  );
};

export default MetaTagsWrapper;
